import React, { useState, useEffect } from "react";
import {
  Container,
  Group,
  Button,
  Modal,
  Text,
  Divider,
  AspectRatio,
  Pill,
  Accordion,
} from "@mantine/core";
import { FaDiscord, FaGoogle } from "react-icons/fa";
import "@mantine/core/styles.css";
import { signInWithGoogle, logOut, auth } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
import ucLogo from "./assets/uc.png";
import umich from "./assets/umich.png";
import cornell from "./assets/cornell.png";
import stanfordLogo from "./assets/stanford.png";
import vanderbiltLogo from "./assets/vanderbilt.png";
import harvardLogo from "./assets/harvard.png";
import mitLogo from "./assets/mit.png";
import demovid from "./assets/demo.mp4";
import tutor from "./assets/tutor.png";
import visual from "./assets/visual.png";
import transcript from "./assets/transcript.png";
import posthog from "posthog-js";

posthog.init("phc_wJW4QdABxfQxWY22hxgiVcGK9N6mknpRTiZahXQOe0v", {
  api_host: "https://us.i.posthog.com",
});

const styles = {
  pageContainer: {
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
    backgroundSize: "cover, cover",
    backgroundRepeat: "no-repeat, no-repeat",
  },
  header: {
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    padding: "0 24px",
    borderBottom: "1px solid #e1e4e8",
    borderRadius: "0 0 20px 20px",
    margin: "0px 20px 0 20px",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  },
  headerGroup: {
    display: "flex",
    flexWrap: "nowrap",
    marginLeft: "auto",
  },
  mainContent: {
    flex: "1",
    textAlign: "center",
    marginTop: "20px",
  },
  title: {
    fontSize: "3.5rem",
    color: "#333",
    marginBottom: '-10px'
  },
  description: {
    fontSize: "1.4rem",
    color: "#666",
    marginBottom: "30px",
  },
  footer: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    padding: "0 24px",
    borderTop: "1px solid #e1e4e8",
    borderRadius: "20px 20px 0 0",
    margin: "0px 20px 0 20px",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  },
  footerLinks: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footerLeft: {
    flexGrow: 1,
    textAlign: "left",
  },
  footerLink: {},
  slideshowContainer: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
  },
  slideshow: {
    display: "flex",
    animation: "scroll 60s linear infinite",
  },
  slideshowImg: {
    height: "100px",
    margin: "0 40px",
  },
  featureContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  featureBox: {
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "20px",
    textAlign: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    margin: "10px",
    flex: "1 1 calc(33.333% - 20px)",
    boxSizing: "border-box",
  },
  video: {
    border: "21px solid #5075B5",
    borderRadius: "30px",
  },
};

const Home = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        navigate("/dashboard");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async () => {
    try {
      posthog.capture("Login Button Clicked");
      await signInWithGoogle();
      setModalOpened(false);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await logOut();
      setModalOpened(false);
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleOpenModal = () => {
    posthog.capture("Sign-Up Modal Opened"); // Track modal open event
    setModalOpened(true);
  };

  const logos = [
    ucLogo,
    umich,
    cornell,
    stanfordLogo,
    vanderbiltLogo,
    harvardLogo,
    mitLogo,
  ];

  return (
    <div style={styles.pageContainer}>
      <header style={styles.header}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 style={{ color: "#5075B5", margin: 0 }}>animanic</h3>
        </div>
        <Group spacing="xs" style={styles.headerGroup}>
          <Button
            style={{ backgroundColor: "white", color: "black" }}
            variant="filled"
            onClick={handleOpenModal}
          >
            {user ? "Log Out" : "Start Learning"}
          </Button>
        </Group>
      </header>
      <Container style={styles.mainContent}>
        <br></br>
        <h1 style={styles.title}>
          Learn more with {" "}
            <a style={{ color: "#5075B5" }}>AI videos</a>
          
        </h1>
        <p style={styles.description}>
          Generate educational videos for any subject in seconds
        </p>
        <br />
        <Button
          style={{
            backgroundColor: "#5075B5",
            borderRadius: "15px",
            padding: "12px 18px", // Increase padding for a larger button
            fontSize: "15px", // Increase font size
            width: "auto", // Optional: Set a specific width
            height: "auto", // Optional: Set a specific height
          }}
          onClick={handleOpenModal}
          variant="filled"
        >
          Get started - it's free!
        </Button>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <AspectRatio
          ratio={1080 / 720}
          maxWidth={500}
          mx="auto"
          style={{ overflow: "hidden", borderRadius: "30px" }}
        >
          <video
            src={demovid}
            autoPlay
            loop
            muted={true}
            controls
            style={styles.video}
          >
            Your browser does not support the video tag.
          </video>
        </AspectRatio>
        <br></br>
        {/*}
        <h2>Trusted by students at..</h2>
        <br></br>
        <div style={styles.slideshowContainer}>
          <div style={styles.slideshow}>
            {logos.map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt="University logo"
                style={styles.slideshowImg}
              />
            ))}
            {logos.map((logo, index) => (
              <img
                key={`duplicate-${index}`}
                src={logo}
                alt="University logo"
                style={styles.slideshowImg}
              />
            ))}
          </div>
        </div>
          */}
        <br></br>
        <br></br>
        <a
          href="https://www.producthunt.com/posts/animanic?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-animanic"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=483879&theme=neutral"
            alt="Animanic - Text-to-video generations for education | Product Hunt"
            style={{ width: "250px", height: "54px" }}
            width="250"
            height="54"
          />
        </a>
        <br></br>
        <br></br>
        <h1>Benefits</h1>
        <br></br>
        <div style={styles.featureContainer}>
          <div style={styles.featureBox}>
            <img
              src={transcript}
              alt="Summarize Your Notes"
              style={{ width: "100%" }}
            />
            <h3 style={{ color: "#5075B5" }}>Describe</h3>
            <p>
              Generate thoughtful audio and text transcripts to understand your
              topics and questions.
            </p>
          </div>
          <div style={styles.featureBox}>
            <img src={visual} alt="Visualizations" style={{ width: "100%" }} />
            <h3 style={{ color: "#5075B5" }}>Visualize</h3>
            <p>
              Utilize the Animanic AI-powered animation engine to illustrate
              complex concepts, beautifully.
            </p>
          </div>
          <div style={styles.featureBox}>
            <img
              src={tutor}
              alt="Chat with Your Notes"
              style={{ width: "100%" }}
            />
            <h3 style={{ color: "#5075B5" }}>Engage</h3>
            <p>
              Chat with Animanic tutor in your videos to answer questions and
              feed your insatiable curiosity.
            </p>
          </div>
        </div>
        <br></br>
        <br></br>
        <h1>FAQ</h1>
        <br></br>
        <Accordion variant="separated">
          <Accordion.Item
            value="credit-card"
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "15px",
            }}
          >
            <Accordion.Control style={{ textAlign: "left" }}>
              <b>How does Animanic work?</b>
            </Accordion.Control>
            <Accordion.Panel style={{ textAlign: "left" }}>
              Users input their notes and questions, along with customizations
              like video duration, to prompt video generation in Animanic. Our{" "}
              <b>AI-powered Animation Engine</b> then crafts a video with audio
              narration for users to enjoy and engage with, featuring an
              interactive chatbot tutor.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            value="payment"
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "15px",
            }}
          >
            <Accordion.Control style={{ textAlign: "left" }}>
              <b>How do I get started with Animanic?</b>
            </Accordion.Control>
            <Accordion.Panel style={{ textAlign: "left" }}>
              Click{" "}
              <b>
                <a onClick={() => setModalOpened(true)}>here</a>
              </b>{" "}
              to create an account, which is linked to Google. Once your account
              is created, you can start generating videos using the intuitive
              Animanic creator dashboard.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            value="a"
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "15px",
            }}
          >
            <Accordion.Control style={{ textAlign: "left" }}>
              <b>How much does Animanic cost?</b>
            </Accordion.Control>
            <Accordion.Panel style={{ textAlign: "left" }}>
              Animanic is <b>completely free</b> to start using! You get three
              free video generations to experience Animanic. Afterward, you can
              upgrade to AnimanicPro for unlimited video generations at an
              affordable monthly subscription.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <br></br>
        <br></br>
        <h1>Learn more and faster.</h1>
        <br></br>
        <Button
          style={{
            backgroundColor: "#5075B5",
            borderRadius: "15px",
            padding: "12px 18px", // Increase padding for a larger button
            fontSize: "15px", // Increase font size
            width: "auto", // Optional: Set a specific width
            height: "auto", // Optional: Set a specific height
          }}
          onClick={handleOpenModal}
          variant="filled"
        >
          Try Animanic for free!
        </Button>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </Container>
      <footer style={styles.footer}>
        <div style={styles.footerLeft}>
          <a
            href="mailto:team@animanic.site"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Pill style={{ backgroundColor: "#ffffff", color: "#5075B5" }}>
              <b>Contact Us</b>
            </Pill>
          </a>
        </div>
        <div style={styles.footerLinks}>
          <div
            onClick={() => handleNavigate("/terms")}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <Pill style={{ backgroundColor: "#ffffff", color: "#5075B5" }}>
              <b>Terms & Conditions</b>
            </Pill>
          </div>
          <div
            onClick={() => handleNavigate("/privacy")}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <Pill style={{ backgroundColor: "#ffffff", color: "#5075B5" }}>
              <b>Privacy Policy</b>
            </Pill>
          </div>
        </div>
      </footer>

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        withCloseButton={false}
        centered
        size="md"
        padding="xl"
        styles={{
          header: { paddingBottom: 0 },
          body: { padding: "20px" },
        }}
        overlayProps={{
          backgroundOpacity: 0.7,
          blur: 4,
        }}
      >
        {user ? (
          <>
            <Text weight={1000} size="lg" style={{ marginBottom: "8px" }}>
              <b>Welcome, {user.displayName}</b>
            </Text>
            <Button
              fullWidth
              variant="outline"
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleLogout}
            >
              Log Out
            </Button>
          </>
        ) : (
          <>
            <Text weight={1000} size="lg" style={{ marginBottom: "8px" }}>
              <b>Create with Animanic</b>
            </Text>
            <Text size="sm" style={{ marginBottom: "20px", color: "#666" }}>
              Accounts are linked to Google. You get a free trial to start!
            </Text>
            <Divider label="CONTINUE WITH" labelPosition="center" />
            <Button
              fullWidth
              variant="outline"
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#5075B5",
              }}
              onClick={handleLogin}
            >
              <FaGoogle size={20} style={{ marginRight: "8px" }} /> Google
            </Button>
          </>
        )}
      </Modal>
      <style jsx="true">{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
      `}</style>
    </div>
  );
};

export default Home;
