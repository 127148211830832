import React, { useState, useEffect } from "react";
import {
  SimpleGrid,
  Modal,
  Button,
  TextInput,
  Grid,
  Container,
  Group,
  Loader,
  FileInput,
  Select,
  Checkbox,
  Textarea,
  Box,
  Text,
  rem,
  ScrollArea,
  Paper,
  Menu,
  Alert,
} from "@mantine/core";
import axios from "axios";
import { FaSignOutAlt, FaBolt, FaPen } from "react-icons/fa";
import { auth, logOut } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getBlob,
  deleteObject,
} from "firebase/storage";
//import { showNotification } from "@mantine/notifications";
import { getCheckoutUrl, getPortalUrl } from "./stripepayment.ts";
import { initFirebase } from "./firebaseConfig";
import { getPremiumStatus } from "./getPremiumStatus.ts";
import posthog from "posthog-js";

posthog.init("phc_wJW4QdABxfQxWY22hxgiVcGK9N6mknpRTiZahXQOe0v", {
  api_host: "https://us.i.posthog.com",
});

const styles = {
  pageContainer: {
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
    backgroundSize: "cover, cover",
    backgroundRepeat: "no-repeat, no-repeat",
  },
  header: {
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    padding: "0 24px",
    borderBottom: "1px solid #e1e4e8",
    borderRadius: "0 0 20px 20px",
    margin: "0px 20px 0 20px",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  },
  headerGroup: {
    display: "flex",
    flexWrap: "nowrap",
    marginLeft: "auto",
  },
  mainContent: {
    textAlign: "left",
    marginTop: "20px",
    padding: "0 24px",
    backgroundColor: "#f8f9fa",
    paddingBottom: "100px",
  },
  footer: {
    backgroundColor: "#ffffff",
    textAlign: "center",
    borderTop: "1px solid #e1e4e8",
    position: "fixed",
    bottom: "0",
    width: "100%",
    margin: "0px 0px 0 0px",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  },
  footerLinks: {
    display: "flex",
    justifyContent: "center",
    marginTop: "8px",
  },
  footerLink: {
    margin: "0 8px",
  },
  video: {
    borderRadius: "20px",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  },
  transcriptBox: {
    borderRadius: "15px",
    border: "1px solid #e1e4e8",
    padding: "10px",
    height: "10em",
    overflow: "auto",
    backgroundColor: "#ffffff !important",
  },
  chatbotContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxHeight: "1000px",
    border: "1px solid #e1e4e8",
    borderRadius: "15px",
    backgroundColor: "#ffffff !important",
    padding: "10px",
  },
  chatHeader: {
    padding: "16px",
    borderBottom: "1px solid #e1e4e8",
    fontWeight: "600",
    fontSize: "1.25rem",
  },
  chatHistory: {
    flex: "1",
    padding: "16px",
    overflowY: "auto",
  },
  chatInputArea: {
    padding: "16px",
    borderTop: "1px solid #e1e4e8",
    display: "flex",
    alignItems: "center",
  },
  textArea: {
    flex: "1",
    marginRight: "8px",
    borderRadius: "15px",
    padding: "8px",
  },
  sendButton: {
    backgroundColor: "#5075B5",
    color: "white",
    border: "none",
    borderRadius: "15px",
    padding: "8px 16px",
  },
  userMessage: {
    backgroundColor: "#F5F9FD",
    padding: "12px",
    borderRadius: "15px",
    marginBottom: "8px",
    alignSelf: "flex-end",
  },
  botMessage: {
    backgroundColor: "#f8f9fa",
    padding: "12px",
    borderRadius: "15px",
    marginBottom: "8px",
    alignSelf: "flex-start",
  },
  selectedButton: {
    backgroundColor: "#5075B5",
    color: "#fff",
    borderRadius: "15px",
  },
  unselectedButton: {
    backgroundColor: "#f0f0f0",
    color: "#000",
    borderRadius: "15px",
  },
  editButton: {
    backgroundColor: "#5075B5",
    color: "#fff",
    borderRadius: "15px",
  },
};

function Dashboard() {
  const [modalOpened, setModalOpened] = useState(false);
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [textPrompt, setTextPrompt] = useState("");
  const [duration, setDuration] = useState(60);
  const [outputFormat, setOutputFormat] = useState("video");
  const [audio, setAudio] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [model, setModel] = useState("gpt-4");
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [videoDetails, setVideoDetails] = useState(null);
  const [videoTitle, setVideoTitle] = useState("");
  const [opened, { toggle }] = useDisclosure(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState("");
  const [showTranscript, setShowTranscript] = useState(true);

  const app = initFirebase();

  const [isPremium, setIsPremium] = useState(false);

  const serverURL =
    "https://web-production-762ec.up.railway.app/https://vishalshenoy--animanic-app-fastapi-modal-app.modal.run/";

  useEffect(() => {
    posthog.capture("Session Started");
    const checkPremium = async () => {
      const newPremiumStatus = auth.currentUser
        ? await getPremiumStatus(app)
        : false;
      setIsPremium(newPremiumStatus);
    };
    checkPremium();
  }, [app, auth.currentUser?.uid]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        setUserName(user.displayName || "User");
        setUserEmail(user.email || "");
        fetchUserVideos(user.email); // Fetch videos directly
      } else {
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const handleDeleteVideo = async () => {
    if (selectedVideo) {
      const videoId = decodeURIComponent(selectedVideo)
        .split("/")
        .pop()
        .split(".")[0];
      try {
        const storage = getStorage();
        const videoRef = ref(storage, `${userEmail}/${videoId}.mp4`);
        const detailsRef = ref(storage, `${userEmail}/details_${videoId}.json`);

        // Delete the video file
        await deleteObject(videoRef);

        // Delete the JSON details file
        await deleteObject(detailsRef);

        setModalOpened(false);
        window.location.reload();
      } catch (error) {
        console.error("Error deleting video:", error);
      }
    }
  };

  const handleButtonClick = () => {
    if (isDeleteMode) {
      handleDeleteVideo();
    } else {
      setIsDeleteMode(true);
    }
  };

  const handleSendMessage = async () => {
    if (chatInput.trim() === "") {
      return;
    }

    const newMessage = { role: "user", content: chatInput };
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { sender: "User", message: chatInput },
    ]);
    setChatInput("");

    const contextMessages = [
      {
        role: "system",
        content:
          videoDetails.tts_script ||
          "You are a helpful assistant who provides expert answers on user queries about a video. The subject and transcript of this video has been provided for you to use as context. Give smart answers and talk about the video like you know what it is, and use the context to answer. Do not bring up you NOT knowing stuff, just infer it.",
      },
      ...chatMessages.map((msg) => ({
        role: msg.sender === "User" ? "user" : "assistant",
        content: msg.message,
      })),
      newMessage,
    ];

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4",
          messages: contextMessages,
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      if (response.data.choices && response.data.choices.length > 0) {
        const messageContent = response.data.choices[0].message.content;
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { sender: "Bot", message: messageContent },
        ]);
      } else {
        console.log("No response in choices");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchUserVideos = async (email) => {
    try {
      const storage = getStorage(app);
      const videosRef = ref(storage, `${email}/`);
      const videoFiles = await listAll(videosRef);

      const videosWithDetails = await Promise.all(
        videoFiles.items
          .filter((item) => item.name.endsWith(".mp4"))
          .map(async (item) => {
            const videoUrl = await getDownloadURL(item);
            const videoId = item.name.split(".")[0];
            const detailsRef = ref(storage, `${email}/details_${videoId}.json`);
            const detailsBlob = await getBlob(detailsRef);
            const detailsText = await detailsBlob.text();
            const details = JSON.parse(detailsText);
            return { url: videoUrl, details };
          })
      );
      setVideos(videosWithDetails);
    } catch (error) {
      console.error("Error fetching user videos:", error);
    }
  };

  const openModal = async (video) => {
    if (video === 1) {
      if (!isPremium) {
        const userDocRef = doc(db, "users", user.email);
        const userDocSnap = await getDoc(userDocRef);
        const videoCount = userDocSnap.data()?.videosGenerated || 0;

        if (videoCount >= 3) {
          setUpgradeModalOpened(true);
          return;
        }
      }
      setCreateModalOpened(true);
      return;
    }
    if (!video) {
      console.error("No video selected");
      return;
    }
    setSelectedVideo(video.url);
    setVideoDetails(video.details);
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalOpened(false);
    setCreateModalOpened(false);
    setSelectedVideo(null);
    setVideoDetails(null);
    setChatMessages([]);
    resetForm();
  };

  const resetForm = () => {
    setTextPrompt("");
    setPdfFile(null);
  };

  const openDrawer = () => {
    setDrawerOpened(true);
  };

  const closeDrawer = () => {
    setDrawerOpened(false);
  };

  // Add a new state for controlling the upgrade modal
  const [upgradeModalOpened, setUpgradeModalOpened] = useState(false);

  // Function to handle opening the upgrade modal
  const openUpgradeModal = () => {
    setUpgradeModalOpened(true);
  };

  // Function to close the upgrade modal
  const closeUpgradeModal = () => {
    setUpgradeModalOpened(false);
  };

  const [error, setError] = useState(false);

  const db = getFirestore(app);

  const createContent = async () => {
    posthog.capture("Generation Button Clicked");
    if (!user) {
      alert("You need to log in first");
      return;
    }

    closeModal();
    const userDocRef = doc(db, "users", user.email);
    const userDocSnap = await getDoc(userDocRef);

    let videoCount = 0;

    if (userDocSnap.exists()) {
      videoCount = userDocSnap.data().videosGenerated || 0;

      const storedIsPremium = userDocSnap.data().isPremium;
      if (storedIsPremium !== isPremium) {
        await updateDoc(userDocRef, { isPremium });
      }
    } else {
      await setDoc(userDocRef, {
        email: user.email,
        isPremium: isPremium,
        videosGenerated: 0,
      });
    }

    if (!isPremium && videoCount >= 3) {
      openUpgradeModal();
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("videoTitle", videoTitle);
      formData.append("duration", duration);
      formData.append("output_format", outputFormat);
      formData.append("audio", audio);
      formData.append("email", user.email);
      formData.append("model", model);
      if (pdfFile) {
        formData.append("file", pdfFile);
      } else {
        formData.append("notes", textPrompt);
      }

      const response = await axios.post(
        `${serverURL}/generate-manim`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log("Response: ", response.data);

      const callId = response.data.call_id;
      //console.log("Call ID: ", callId);

      const checkStatus = async () => {
        const statusResponse = await axios.get(
          `${serverURL}/poll-manim/${callId}`
        );
        if (statusResponse.status === 202) {
          //console.log("Status response: ", statusResponse.data);
          //console.log("waiting for video to be generated...");
          setTimeout(checkStatus, 10000); // Poll every 10 seconds
        } else {
          const videoUrl = statusResponse.data.video_url;
          if (videoUrl) {
            //console.log("Video URL: ", videoUrl);
            await updateDoc(userDocRef, {
              videosGenerated: increment(1),
            });
            const videoId = videoUrl.split("/").pop().split(".")[0];
            const detailsResponse = await axios.get(
              `${serverURL}/get-video-details/${user.email}/${videoId}`
            );
            const newVideo = { url: videoUrl, details: detailsResponse.data };
            setVideos([...videos, newVideo]);
            setLoading(false);
            window.location.reload();
          } else {
            //console.error("Error creating content:", error);
            setLoading(false);
            setErrorModal(true);
          }
        }
      };

      checkStatus();
    } catch (error) {
      //console.error("Error creating content:", error);
      setLoading(false);
      setErrorModal(true);
    }
  };

  const [errorModal, setErrorModal] = useState(false);

  const closeErrorModal = () => {
    setErrorModal(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents the default newline insertion
      handleSendMessage();
    }
  };

  const performLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const upgrateToPremium = async () => {
    const priceId = "price_1PlLQMKLSiATAYLrMJK68WHc";
    const checkoutUrl = await getCheckoutUrl(app, priceId);
    window.location.href = checkoutUrl;
    //console.log("Upgrading to Premium");
  };

  const manageSubscription = async () => {
    const portalUrl = await getPortalUrl(app);
    window.location.href = portalUrl;
    //console.log("Upgrading to Premium");
    //console.log("Portal URL: ", portalUrl);
  };

  return (
    <div style={styles.pageContainer}>
      <header style={styles.header}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 style={{ color: "#5075B5", margin: 0 }}>animanic</h3>
        </div>
        <Group spacing="xs" style={styles.headerGroup}>
          <Menu
            shadow="md"
            width={220}
            trigger="click-hover"
            openDelay={100}
            closeDelay={400}
            position="top-end"
            offset={5}
          >
            <Menu.Target>
              <Button
                style={{ backgroundColor: "white", color: "black" }}
                variant="filled"
              >
                My Account
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label style={{ color: "black", fontSize: "0.9rem" }}>
                Hi, <b>{userName}</b>!
              </Menu.Label>
              <Menu.Label>
                {" "}
                {isPremium ? "AnimanicPro Tier" : "Free Tier"}
              </Menu.Label>
              <Menu.Label>{userEmail}</Menu.Label>
              <Menu.Divider />
              <Menu.Item
                leftSection={
                  <FaBolt
                    style={{
                      width: rem(14),
                      height: rem(14),
                      color: "#5075B5",
                    }}
                  />
                }
                onClick={() => {
                  if (isPremium) {
                    manageSubscription();
                  } else {
                    upgrateToPremium();
                  }
                }}
              >
                {isPremium ? "Manage Subscription" : "Upgrade to AnimanicPro"}
              </Menu.Item>
              <Menu.Item
                leftSection={
                  <FaPen style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() =>
                  window.open("https://forms.gle/PRNwqPAVDjj8tpK27", "_blank")
                }
              >
                Give Feedback
              </Menu.Item>
              <Menu.Item
                leftSection={
                  <FaSignOutAlt style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={performLogout}
              >
                Log Out
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </header>
      {loading && (
        <Alert
          variant="light"
          color="#5075B5"
          title="Keep this page open!"
          style={{
            marginTop: "15px",
            marginRight: "20px",
            marginLeft: "20px",
            rounded: "10px",
          }}
        >
          Please keep this page open and do not reload the page while your video
          is being generated. It can take up to a few minutes!
        </Alert>
      )}
      {error && (
        <Alert
          variant="light"
          color="red"
          title="Generation failed"
          style={{
            marginTop: "15px",
            marginRight: "20px",
            marginLeft: "20px",
            rounded: "10px",
          }}
        >
          Uh oh! Something went wrong. Please try again later.
        </Alert>
      )}
      <Container
        style={{
          padding: "20px",
          maxWidth: "100%",
          justifyContent: "center",
          marginTop: "10px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <SimpleGrid
          cols={{ base: 1, sm: 2, lg: 3 }}
          spacing="md"
          style={{ width: "100%" }}
        >
          <div
            onClick={() => {
              if (!loading) {
                openModal(1);
              }
            }}
            style={{
              backgroundColor: "#f8f9fa",
              cursor: "pointer",
              padding: "20px",
              color: "black",
              textAlign: "center",
              margin: "5px",
              border: "3px dotted #000000",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "220px",
              borderRadius: "20px",
            }}
          >
            <div style={{ fontSize: "5rem", fontWeight: "bold" }}>
              {loading ? <Loader color="black" size="lg" /> : "+"}
            </div>
            <div
              style={{
                fontSize: "1.1rem",
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Create Video
            </div>
          </div>
          {videos.map((video, index) => (
            <div
              key={index}
              onClick={() => openModal(video)}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                backgroundImage: 'url("topography.svg")',
                cursor: "pointer",
                padding: "20px",
                color: "white",
                textAlign: "center",
                margin: "5px",
                borderRadius: "20px",
                minHeight: "220px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Text style={{ fontWeight: "bold", color: "black" }}>
                  {video.details.videoTitle}
                </Text>
              </Box>
            </div>
          ))}
        </SimpleGrid>
      </Container>
      <Modal
        opened={errorModal}
        onClose={closeErrorModal}
        title={<b style={{ color: "#c0392b" }}>Generation Failed</b>}
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <Text>
          Unfortunately, due to model constraints, we are unable to generate
          your video at this time. Please try again later, or contact
          team@animanic.site if the issue persists.
        </Text>
        <Group position="right" mt="md"></Group>
      </Modal>
      <Modal
        opened={upgradeModalOpened}
        onClose={closeUpgradeModal}
        title={<b>Limit Reached</b>}
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        withCloseButton={false}
      >
        <Text>
          You have reached the limit of one free video generation on a standard
          account. Upgrade to <b>AnimanicPro</b> to generate unlimited,
          high-quality videos with audio narration.
        </Text>
        <Group position="right" mt="md">
          <Button
            leftSection={
              <FaBolt
                style={{
                  width: rem(14),
                  height: rem(14),
                  color: "#ffffff",
                }}
              />
            }
            onClick={upgrateToPremium}
            style={{ backgroundColor: "#5075B5" }}
          >
            Upgrade Now
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={modalOpened}
        onClose={closeModal}
        title={
          <h3>{videoDetails ? videoDetails.videoTitle : "Video Details"}</h3>
        }
        centered
        fullScreen
        styles={{
          modal: {
            borderRadius: "15px",
            backgroundColor: "#f8f9fa",
          },
        }}
      >
        {selectedVideo && videoDetails ? (
          <Grid>
            <Grid.Col span={8}>
              <video width="100%" controls style={styles.video}>
                <source src={selectedVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <Button
                    style={{
                      ...styles.button,
                      ...(showTranscript
                        ? styles.selectedButton
                        : styles.unselectedButton),
                      marginRight: "10px",
                    }}
                    onClick={() => setShowTranscript(true)}
                  >
                    Transcript
                  </Button>
                  <Button
                    style={{
                      ...styles.button,
                      ...(!showTranscript
                        ? styles.selectedButton
                        : styles.unselectedButton),
                    }}
                    onClick={() => setShowTranscript(false)}
                  >
                    Notes
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  {isDeleteMode && (
                    <Button
                      style={{ ...styles.editButton, marginRight: "10px" }}
                      onClick={() => setIsDeleteMode(false)}
                    >
                      X
                    </Button>
                  )}
                  <Button
                    style={{
                      backgroundColor: isDeleteMode ? "#c0392b" : "#5075B5",
                      color: "white",
                      borderRadius: "15px",
                    }}
                    onClick={handleButtonClick}
                  >
                    {isDeleteMode ? "Delete Video" : "Manage Video"}
                  </Button>
                </div>
              </div>
              <div className="p-4 overflow-auto" style={styles.transcriptBox}>
                {showTranscript ? videoDetails.tts_script : videoDetails.notes}
              </div>
            </Grid.Col>
            <Grid.Col span={4}>
              <Box style={styles.chatbotContainer}>
                <div style={styles.chatHeader}>Animanic Tutor</div>
                <ScrollArea style={styles.chatHistory}>
                  {chatMessages.map((msg, index) => (
                    <Paper
                      key={index}
                      style={
                        msg.sender === "User"
                          ? styles.userMessage
                          : styles.botMessage
                      }
                    >
                      <Text>{msg.message}</Text>
                    </Paper>
                  ))}
                </ScrollArea>
                <div style={styles.chatInputArea}>
                  <Textarea
                    placeholder="Chat with your video.."
                    value={chatInput}
                    onChange={(event) =>
                      setChatInput(event.currentTarget.value)
                    }
                    style={styles.textArea}
                    onKeyPress={handleKeyPress}
                    autosize
                    minRows={1}
                    maxRows={3}
                  />
                  <Button onClick={handleSendMessage} style={styles.sendButton}>
                    Send
                  </Button>
                </div>
              </Box>
            </Grid.Col>
          </Grid>
        ) : (
          <Loader />
        )}
      </Modal>
      <Modal
        opened={createModalOpened}
        onClose={closeModal}
        title={<b>Create video</b>}
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        size="lg"
      >
        <div>
          <TextInput
            label="Lecture Title"
            required
            placeholder="Enter a title for your video"
            value={videoTitle}
            onChange={(event) => setVideoTitle(event.currentTarget.value)}
          />
          <br />
          <Select
            label="Video Duration"
            value={duration}
            onChange={(value) => setDuration(value)}
            data={[
              { value: "30", label: "Brief" },
              { value: "60", label: "Regular" },
              { value: "90", label: "Extended" },
            ]}
            required
          />
          <br />
          <Select
            label="Choose Model"
            placeholder="Pick one"
            data={[{ value: "gpt-4", label: "Manic-1" }]}
            value={model}
            onChange={setModel}
            required
          />
          <br />
          <Box bg="#f5f5f5" p="md" style={{ borderRadius: "10px" }}>
            <Textarea
              label="Add your notes or question(s)"
              placeholder="Enter your notes"
              minRows={3}
              value={textPrompt}
              onChange={(event) => setTextPrompt(event.currentTarget.value)}
              disabled={pdfFile !== null}
            />
            <center>
              <br></br>
              <b>or</b>
              <br></br>
            </center>
            <FileInput
              label="Attach your notes or question(s)"
              placeholder="Upload PDF file"
              value={pdfFile}
              onChange={setPdfFile}
              accept="application/pdf"
              disabled={textPrompt !== ""}
            />
            {pdfFile && (
              <Button
                onClick={() => setPdfFile(null)}
                variant="light"
                style={{
                  marginTop: "10px",
                }}
              >
                Clear PDF
              </Button>
            )}
          </Box>
          <br />
          <Checkbox
            label="Include audio narration (AnimanicPro only)"
            checked={audio}
            onChange={(event) => setAudio(event.currentTarget.checked)}
            color="#5075B5"
            disabled={!isPremium}
          />
          <Button
            style={{ marginTop: "2rem", backgroundColor: "#5075B5" }}
            onClick={createContent}
          >
            {loading ? (
              <Loader color="white" size="sm" type="bars" />
            ) : (
              "Generate"
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Dashboard;
